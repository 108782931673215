import { graphql } from 'gatsby'
import React from 'react'

import Breadcrumb from '../../components/breadcrumb'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Link from '../../components/link'
import List from '../../components/list'
import ListItem from '../../components/list-item'
import SEO from '../../components/seo'


export const query = graphql`
  query ClocksSitemapPageQuery {
    ...SiteInfo
    results: postgres {
      clocks: allClocksList {
        ...Clock
      }
    }
  }
`

export default ({ data }) => {
  const { links, meta } = data.site.siteMetadata
  const { clocks } = data.results
  const { entity } = meta

  const page = `${entity.singular} Times of Day Sitemap`
  const title = `${page} - Sitemaps`
  const keywords = title.split(/\W+/)
  const trail = [
    {
      name: 'Sitemap',
      slug: links.sitemap.home,
    },
    {
      name: 'Times of Day',
      slug: links.sitemap.clocks,
    }
  ]
  const schema = {
    breadcrumb: trail,
    description: title,
    keywords,
    name: title,
    slug: links.sitemap.clocks,
  }

  return (
    <Layout>
      <SEO keywords={keywords} schema={schema} title={title} />
      <Breadcrumb trail={trail} />

      <Heading>{page}</Heading>

      <List>
        <ListItem>
          <Link href={links.clocks}>All Times of Day</Link>
        </ListItem>
        {clocks.map(clock => (
          <ListItem key={clock.id}>
            <Link href={clock.slug}>{clock.name}</Link>
          </ListItem>
        ))}
      </List>

      <Breadcrumb trail={trail} />
    </Layout>
  )
}
